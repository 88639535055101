<template>
     <CommonBlock
            :title=this.category.title
            :visible-back-btn="true"
            :breadcrumbs="breadcrumbs"
            :cancelBackBtnEventPassed="true"
            @clickBack="clickBack"
     >
        <p class="category-text">{{ this.category.description }}</p>
    </CommonBlock>
</template>

<script>
    import StudyService from "../../services/StudyService";
    import CommonBlock from "@/components/CommonBlock";

    export default {
        name: "CategorySidebar",
        props: {
            id: {
                required: true
            },
            category: { }
        },
         data() {
            return {
                breadcrumbs: [
                    {id: 1, name: 'Навчання', link: '/study' },
                ],
                view: {},
            };
        },
        components: {
            CommonBlock
        },
        async beforeMount() {
            this.category = await StudyService.getCategory(this.id);
            this.breadcrumbs.push({id: 2, name: this.category.title })
        },
        methods: {
            clickBack() {
                if(Object.keys(this.view).length) {
                    this.view = {};
                }else{
                    this.$router.go(-1);
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .common-block {
        background: #1a2744;
    }
    .category-text {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.14px;
        color: #fff;
        margin-bottom: 30px;
        @media screen and (max-width: 1600px) {
            font-size: 18px;
            line-height: 26px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 16px;
          line-height: 24px;
        }
    }

</style>
