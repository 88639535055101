<template>
    <section class="category">
        <div class="category-wrapper">
            <category-sidebar :id="this.$route.params.category" />
            <main class="content">
                <div class="content-top">
                    <h3 class="content_main-title">{{ course.title }}</h3>
                    <h5 class="content-description">{{ course.category_title }}</h5>
                </div>
                <div v-if="youtubeSrc" class="player">
                    <iframe width="100%" height="340" :src="youtubeSrc" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
                <div class="content-inner" v-html="course.text"></div>
                <div class="content-files-bg" v-if="course.files.length">
                    <div class="br"></div>
                    <header>Додані файли:</header>
                    <ul class="content-files">
                        <li v-for="file in course.files">
                            <span :class="`fiv-sqo fiv-icon-${file.extension}`"></span>
                            <span class="filename">{{file.name}}</span>
                            <div class="right-position">
                                <span class="size">{{formatBytes(file.size)}}</span>
                                <a :href="file.url" download class="file-download">
                                    <svg width="16" height="18" viewBox="0 0 14 14">
                                        <path
                                            d="M12.877 8.39999C12.2568 8.39999 11.754 8.85147 11.754 9.40834V11.9833H2.24599V9.40834C2.24599 8.85147 1.74319 8.39999 1.12299 8.39999C0.502802 8.39999 0 8.85147 0 9.40834V12.9916C0 13.5485 0.502802 14 1.12299 14H12.877C13.4972 14 14 13.5485 14 12.9916V9.40834C14 8.85143 13.4972 8.39999 12.877 8.39999Z"/>
                                        <path
                                            d="M9.32476 4.34564L8.09853 5.68954V1.20392C8.09853 0.539036 7.60669 0 7.00002 0C6.39335 0 5.90151 0.539036 5.90151 1.20392V5.68954L4.67528 4.34564C4.46082 4.11059 4.17967 3.99301 3.89853 3.99301C3.61738 3.99301 3.33628 4.11059 3.12177 4.34564C2.69281 4.81585 2.69281 5.57809 3.12177 6.04823L6.22327 9.44734C6.65223 9.91755 7.34781 9.91755 7.77678 9.44734L10.8783 6.04823C11.3073 5.57809 11.3073 4.81585 10.8783 4.34564C10.4493 3.87543 9.75373 3.87543 9.32476 4.34564Z"/>
                                    </svg>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </main>
        </div>
    </section>
</template>

<script>
    import StudyService from "../services/StudyService";
    import CategorySidebar from "../components/study/CategorySidebar";


    export default {
        name: "StudyContent",
        components: {CategorySidebar},
        data() {
            return {
                course: {},
                youtubeSrc: ''
            }
        },
        async beforeMount() {
            this.course = await StudyService.getCourse(this.$route.params.id);
            document.title = this.course.title;
            if(this.course.youtube_url) {
                this.youtubeSrc = "https://www.youtube.com/embed/" + this.getYoutubeIdFromUrl(this.course.youtube_url);
            }
        },
        methods: {
            formatBytes(bytes, decimals = 2) {
                if (bytes === 0) {
                    return '0 Bytes'
                }

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
            getYoutubeIdFromUrl(url) {
                let video_id = url.split('v=')[1];
                let ampersandPosition = video_id.indexOf('&');
                if(ampersandPosition !== -1) {
                    video_id = video_id.substring(0, ampersandPosition);
                }
                return video_id;
            }
        }
    }
</script>

<style>
@import "../assets/css/file-icon-square-o.css";
</style>

<style scoped lang="less">
    .content-files-bg{
        .br{
            background-color: rgba(0,0,0,.15);
            width: 100%;
            height: 1px;
            margin: 40px 0 30px;
        }
        header{
            font-size: 20px;
            line-height: 1.2;
            color: #1a2744;
            margin-bottom: 20px;
        }
    }
    .content-files{
        list-style: none;
        li{
            padding: 4px 4px 4px 20px;
            background-color: #fff;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: -0.13px;
            color: #1a2744;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            .fiv-sqo{
                min-width: 18px;
            }
            .right-position{
                flex: 1 1 auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
            .filename{
                margin-left: 20px;
                display: inline-block;
            }
            .size{
                float: right;
                white-space: nowrap;
                margin-left: 20px;
            }
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 40px;
                width: 40px;
                height: 40px;
                border-radius: 15px;
                box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
                margin-left: 20px;
                background-color: #f5f5f5;
                transition: background-color .15s linear;
                svg{
                    fill: #1a2744;
                    transition: fill .15s linear;
                }
                &:hover{
                    background-color: #ffc900;
                    svg{
                        fill: #fff;
                    }
                }
            }
        }
    }
    .category {
        background: #F2F2F2;

        &-wrapper {
            display: flex;
        }

        &-inner {
            height: 100vh;
            width: 30%;
            background: #1A2744;
            padding: 80px 37px 80px 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;
        }

        &-title {
            font-weight: bold;
            font-size: 56px;
            line-height: 64px;
            letter-spacing: -0.392px;
            color: #fff;
            margin-bottom: 40px;
        }

        &-text {
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.14px;
            color: #FFFFFF;
            margin-bottom: 35px;
        }
    }

    .content {
        padding: 80px 40px 20px 40px;
        width: 70%;
        height: 100vh;
        overflow-y: auto;

        &-section {
            margin-bottom: 40px;
        }

        &-top {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        &_main-title {
            font-weight: bold;
            font-size: 32px;
            line-height: 40px;
            color: #1A2744;
            margin-bottom: 10px;
        }

        &-description {
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.14px;
            color: #1A2744;
        }

        &-img {
            // height: 256px;
            // max-width: 580px;
            width: 48%;
            margin-right: 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-info {
            //  max-width: 580px;
            width: 48%;
        }

        &-title {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.168px;
            color: #1A2744;
            margin-bottom: 20px;
        }

        &-text {
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.14px;
            color: #1A2744;
        }
    }


    .d-flex {
        display: flex;
    }

    .player {
        margin-bottom: 60px;
    }

    video {
        width: 100%;
    }

    @media (min-aspect-ratio: 16/9) {
        .video {
            width: 100%;
            object-fit: cover;
        }
    }

    @media (max-aspect-ratio: 16/9) {
        .video {
            width: 100%;
            object-fit: cover;
        }
    }

    .header {
        width: 100%;
    }

    .header-logo {
        //   width: 253px;
        height: 51px;
        margin-bottom: 22px;
        display: inline-block;

        img {
            width: 100%;
            height: 100%;
        }
    }

    /* breadcrumb */
    .breadcrumb {
        list-style: none;
        margin-bottom: 45px;
    }

    .breadcrumb-item {
        display: inline;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #fff;
        margin-right: 20px;
    }

    .breadcrumb-link {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #ffc900;
        text-decoration: none;
        position: relative;

        &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 15px;
            background: #fff;
            bottom: 1px;
            right: -11px;
        }
    }


    .btn-back {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #ffc900;
        text-decoration: none;

        &_icon {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background: #ffc900;
            box-shadow: 0px 24px 24px -14px rgba(249, 198, 35, 0.35);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
        }
    }

    @media screen and (max-width: 1920px) {
        .category {
            &-inner {
                height: 100vh;
                max-width: 640px;
                width: 30%;
            }

            &-content {
                max-width: 1280px;
                height: 100vh;
                max-width: 1280px;
                width: 70%;
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .category {
            &-inner {
                padding: 40px 40px 40px 60px;
            }

            &-title {
                font-size: 36px;
                line-height: 42px;
                margin-bottom: 20px;
            }

            &-text {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 20px;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .category {
            &-title {
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 20px;
            }

            &-text {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .category-inner {
            padding: 40px 40px 40px 50px;
        }

        .content {
            padding: 40px 40px 20px 40px;
        }
    }
    @media screen and (max-width: 992px) {
         .content {
            width: 100%;
            height: 100%;
            padding: 30px;
        }
        a {
        word-break: break-all !important;
        }
    }

    @media screen and (max-width: 992px) {
        .category-wrapper {
            display: block;
        }
        .content {
            padding: 20px;
        }
    }
    .common-block{
        @media (max-height: 992px){
            min-height: auto!important;
        }
    }
    ::v-deep{
        .common-block{
            @media (max-height: 992px){
                min-height: auto!important;
            }
        }
    }
</style>


<style lang="less">
    .content {
        &-inner {
            a {
                overflow-wrap: break-word;
            }
        }
    }

</style>


