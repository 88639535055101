import apiClient from "./ApiService";

export default {
    getCategories(limit = 20, offset = 0) {
        return apiClient.get('course-category/list', { params: { limit: limit, offset: offset } })
    },
    getCategory(id) {
        return apiClient.get('course-category', {params: {id: id}});
    },
    getCourses(limit = 20, offset = 0, category_id = 1) {
        return apiClient.get('course/list', { params: { limit: limit, offset: offset, category_id: category_id } })
    },
    getCourse(id) {
        return apiClient.get('course', {params: {id: id}});
    },
}
